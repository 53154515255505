import ReactGA from "react-ga";
import config from "config";

/**
 * @description Page view tracking
 * @param {string} page - The page to track
 */

export function pageViewTracking({ pageview }: { pageview: string }) {
  return ReactGA.pageview(pageview);
}

/**
 * @description Initialize Google Analytics
 */

export function run() {
  return ReactGA.initialize(config.google.clientId);
}
